
#id6542e01cc239522da2e3aca6 {
  #i25m {
min-height : 100px; 
display : flex; 
} 
#i1au {
padding : 10px; 
min-height : 60px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 12vh; 
} 
#ib43 {
min-height : 100px; 
width : 90%; 
} 
#iq9l {
padding : 10px; 
min-height : 100px; 
width : 10%; 
display : flex; 
flex-direction : column; 
align-items : center; 
justify-content : flex-start; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 100vh; 
} 
#ievj {
padding : 10px; 
min-height : 100px; 
height : 88vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
} 
#iss9f {
color : black; 
margin : 10px 0 0 0; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ify7b {
width : 100%; 
display : block; 
} 
#i8eor {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iucyx {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i73es {
color : black; 
width : 75px; 
} 
#il6cc {
color : black; 
width : 45px; 
height : 45px; 
} 
#ijvrd {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9p3l {
padding : 10px; 
min-height : 300px; 
width : 100%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
flex-direction : column; 
} 
#i9sfh {
color : black; 
width : 107px; 
height : 107px; 
} 
#iq338 {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9obh {
color : black; 
width : 50px; 
height : 50px; 
} 
#i76os {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ixp2y {
color : black; 
width : 75px; 
} 
#irruz {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iyz7i {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ihmgh {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
height : 10vh; 
} 
#i8iqk {
padding : 10px; 
height : 68.18181610107422px; 
} 
#ikbwp {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#ivg2s {
color : black; 
width : 50px; 
} 
#ivoo4 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 10px 10px 10px 0px; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ipcv9 {
padding : 10px; 
display : block; 
font-size : 2rem; 
text-align : center; 
font-weight : 800; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ig0z1 {
display : block; 
} 
#iho4a {
padding : 10px; 
display : block; 
} 
#ijwdx {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#iy67i {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i5j7v {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#igye5 {
font-size : 12pt; 
line-height : 17.12px; 
} 
#iqcju {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#in4zx {
font-size : 12pt; 
line-height : 17.12px; 
} 
#insth {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iqpsh {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i8c4m {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#i0s6q {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#i5t3g {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#iymth {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#ipvvc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iyahp {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#i2f4ot {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ikgm1g {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i705jx {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#isrb44 {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ih112i {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iqqm4l {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ic45mk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#in7otb {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#i4ibum {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#i2us5f {
font-size : 12pt; 
line-height : 17.12px; 
} 
#io4kj5 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iukewc {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i3o02k {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iday5x {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#ite4g3 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ivyzvf {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ixrkrf {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ieasqf {
font-size : 12pt; 
line-height : 17.12px; 
} 
#istwzk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ihf5vc {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i2tp6j {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#i7qbks {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#if6qiy {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ib9l1d {
font-size : 12pt; 
line-height : 17.12px; 
} 
#iazd9a {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iatc6h {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ipnvkb {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ipkzva {
font-size : 12pt; 
line-height : 17.12px; 
} 
#imbzqk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#is19b9 {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i0gc8b {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#i6pecc {
font-size : 12pt; 
line-height : 17.12px; 
} 
#iv1489 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ixnkbz {
font-size : 12pt; 
line-height : 17.12px; 
} 
#iza0l2 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ioc4ig {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#inwgi9 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#irp48t {
font-size : 12pt; 
line-height : 17.12px; 
} 
#irb07r {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#i1ddrf {
font-size : 12pt; 
line-height : 17.12px; 
} 
#itf7f6 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iwxw4b {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#i5cuq3 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iad8y2 {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ibqyi6 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iib5vv {
font-size : 12pt; 
line-height : 17.12px; 
} 
#it4b6k {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ieffcm {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#iddeot {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iwqyoo {
font-size : 12pt; 
line-height : 17.12px; 
} 
#in8esf {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ium5xi {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i1w2fi {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#idd29h {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#itj0rk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ietz03 {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ii7irh {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ilff8u {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ial5y4 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iadyij {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ijjbxg {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ilhx2r {
font-size : 12pt; 
line-height : 17.12px; 
} 
#iz44d5 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iyb1eo {
font-size : 12pt; 
line-height : 17.12px; 
} 
#iemmoq {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ighoan {
font-size : 12pt; 
line-height : 17.12px; 
} 
#imsen7 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#idef9t {
font-size : 12pt; 
line-height : 17.12px; 
} 
#i7jull {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#i2bxqs {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#i590rs {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#ijnz2f {
font-size : 12pt; 
line-height : 17.12px; 
} 
#ihz4mj {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#i3htlx {
font-size : 12pt; 
line-height : 17.12px; 
} 
#id88lf {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iasyof {
font-size : 14pt; 
line-height : 19.9733px; 
} 
#iyoe5s {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
} 
#iw5ram {
font-size : 12pt; 
line-height : 17.12px; 
} 

  @media only screen and (max-width: 992px) {#ib43 {
width : 85%; 
}} 
@media only screen and (max-width: 992px) {#iq9l {
width : 15%; 
}} 

  
}
  