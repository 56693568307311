
#id65c87b14b0184704f908ff00 {
  #i25m {
min-height : 100px; 
display : flex; 
} 
#i1au {
padding : 10px; 
min-height : 60px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 12vh; 
} 
#ib43 {
min-height : 100px; 
width : 90%; 
} 
#iq9l {
padding : 10px; 
min-height : 100vh; 
width : 10%; 
display : flex; 
flex-direction : column; 
align-items : center; 
justify-content : flex-start; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#ievj {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
} 
#iss9f {
color : black; 
margin : 10px 0 0 0; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ify7b {
width : 100%; 
display : block; 
} 
#i8eor {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iucyx {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i73es {
color : black; 
width : 75px; 
} 
#il6cc {
color : black; 
width : 45px; 
height : 45px; 
} 
#ijvrd {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9p3l {
padding : 10px; 
min-height : 300px; 
width : 100%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
flex-direction : column; 
} 
#i9sfh {
color : black; 
width : 107px; 
height : 107px; 
} 
#iq338 {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9obh {
color : black; 
width : 50px; 
height : 50px; 
} 
#i76os {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ixp2y {
color : black; 
width : 75px; 
} 
#irruz {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iyz7i {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ihmgh {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
height : 10vh; 
} 
#i8iqk {
padding : 10px; 
height : 68.18181610107422px; 
} 
#ikbwp {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#ivg2s {
color : black; 
width : 50px; 
} 
#ivoo4 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 10px 10px 10px 0px; 
} 
#ig0z1 {
display : block; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-three-swiper-1 {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-three-swiper-2 {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-three-swiper-3 {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
#i99nx {
padding : 10px; 
} 
#ilq6k {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i9jci {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ijsmh {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
.swiper-slide.item-threecarrusel1 {
width : 230px; 
} 
.swiper-slide.item-threecarrusel2 {
width : 230px; 
} 
.swiper-slide.item-threecarrusel3 {
width : 230px; 
} 
.swiper.gjs-three-swiper-1 {
height : auto; 
float : none; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i0ocz {
padding : 5px; 
width : 30%; 
height : auto; 
} 
#i9p7e8 {
padding : 5px; 
width : 30%; 
height : auto; 
} 
#i9m9vs {
padding : 5px; 
width : 30%; 
height : auto; 
} 
.gjs-three-swiper-2.swiper {
height : auto; 
} 
.swiper.gjs-three-swiper-3 {
height : auto; 
} 

  @media only screen and (max-width: 992px) {#ib43 {
width : 85%; 
}} 
@media only screen and (max-width: 992px) {#iq9l {
width : 15%; 
}} 
@media only screen and (max-width: 992px) {#i9m9vs {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#i0ocz {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#i9p7e8 {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#ievj {
min-height : 100vh; 
}} 

  @media only screen and (max-width: 480px) {#i0ocz {
height : 82.75px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ilq6k {
width : 310%; 
}} 
@media only screen and (max-width: 480px) {#i9p7e8 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i9m9vs {
width : 100%; 
}} 

}
  