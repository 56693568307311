
#id64dd2d649255464525647cb2 {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#i3vl {
color : black; 
height : 100%; 
} 
#ij65 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
height : 88vh; 
justify-content : center; 
display : flex; 
align-items : flex-start; 
border : 1px solid #5e6164; 
} 
#ix7n {
padding : 10px; 
min-height : 100px; 
width : 45%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
margin : 2.5rem 0px 0px 0px; 
box-shadow : 1px 3px 20px -4px black ; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i5zr {
min-height : 100px; 
padding : 10px; 
} 
#iuxdl {
padding : 10px; 
display : flex; 
flex-direction : column; 
align-items : center; 
} 
#iqs9f {
padding : 10px; 
display : flex; 
flex-direction : column; 
align-items : center; 
align-self : auto; 
justify-content : flex-start; 
width : 70%; 
} 
#iousf {
padding : 10px; 
display : flex; 
flex-direction : column; 
align-self : auto; 
align-items : center; 
width : 70%; 
margin : 0 0 1rem 0; 
} 
#iedkh {
width : 45%; 
float : center; 
border-radius : 100px 100px 100px 100px; 
border : 1px solid #5e6164; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
font-family : Verdana, Geneva, sans-serif; 
color : white; 
padding : 0.25rem 0px 0.25rem 0px; 
} 
#iosji {
padding : 10px; 
min-height : 100px; 
} 
#ilyti {
font-family : Verdana, Geneva, sans-serif; 
font-size : 0.9rem; 
color : #5e6164; 
} 
#irt2n {
padding : 10px; 
display : flex; 
float : center; 
justify-content : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 1.2rem; 
color : #5e6164; 
} 
#i6h3f {
font-family : Verdana, Geneva, sans-serif; 
font-size : 0.9rem; 
color : #5e6164; 
} 
#iox85 {
border : 1px solid #5e6164; 
width : 100%; 
} 
#i4g29 {
border : 1px solid #5e6164; 
width : 100%; 
} 
#i80xk {
padding : 0px 0px 0px 0px; 
min-height : 1px; 
margin : 1.5rem 0px 0.5rem 0px; 
width : 50%; 
border : 1px solid #000000; 
} 
#iyb74 {
padding : 10px; 
display : block; 
color : #5e6164; 
font-family : Verdana, Geneva, sans-serif; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ibkrl {
font-family : Verdana, Geneva, sans-serif; 
} 
#ia12i {
height : 21px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#i64nj {
width : 45%; 
display : block; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 100px 100px 100px 100px; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 1rem; 
color : #ffffff; 
padding : 5px 5px 5px 5px; 
} 
#i4ca4 {
display : block; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.input-mixclo {
padding : 5px 5px 5px 5px; 
} 

  @media only screen and (max-width: 992px) {#ix7n {
width : 70%; 
}} 

  @media only screen and (max-width: 480px) {#iedkh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ix7n {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqs9f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iousf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iyb74 {
float : center; 
text-align : center; 
}} 

}
  