
#id651b3ff0925546452564e049 {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
align-items : center; 
} 
#i3vl {
color : black; 
width : 142px; 
} 
#ij65 {
min-height : 100vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
justify-content : space-between; 
display : flex; 
align-items : flex-start; 
border : 1px solid #5e6164; 
padding : 0px 15px 0px 15px; 
flex-wrap : wrap; 
} 
#ix7n {
padding : 0px 0px 0px 0; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
margin : 2.5rem 0px 0px 0px; 
box-shadow : 1px 3px 20px -4px black ; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#iosji {
padding : 10px; 
min-height : 100px; 
} 
#irt2n {
padding : 30px 10px 30px 10px; 
display : flex; 
float : center; 
justify-content : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 25px; 
color : black; 
font-weight : 400; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#i5u8d {
height : 0px; 
border : 1px solid black; 
box-shadow : 0px 5px 10px 0px black ; 
} 
.checkboxMain {
width : 300px; 
padding : 0px 0px 0px 0px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
.test {
padding : 0 0px 0px 0px; 
} 
.fullWidth {
width : 100%; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#idxbx {
display : block; 
} 
#i0njf {
padding : 10px; 
min-height : 100px; 
width : 40%; 
} 
#iqzlf {
padding : 30px 10px 30px 10px; 
display : flex; 
float : center; 
justify-content : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 25px; 
color : black; 
font-weight : 400; 
} 
#iy57p {
height : 0px; 
border : 1px solid black; 
box-shadow : 0px 5px 10px 0px black ; 
} 
#ihktr5 {
padding : 10px; 
min-height : 100px; 
} 
#ix0scd {
padding : 0px 0px 0px 0; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
margin : 2.5rem 0px 0px 0px; 
box-shadow : 1px 3px 20px -4px black ; 
} 
#i8hrnl {
padding : 10px; 
min-height : 100px; 
width : 40%; 
} 
#i8ntp {
padding : 10px; 
min-height : 100px; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 18px; 
} 
#i24mx4 {
padding : 10px; 
display : block; 
} 
#icss0d {
width : 58.1%; 
display : flex; 
font-family : Verdana, Geneva, sans-serif; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 15px 15px 15px 15px; 
justify-content : space-around; 
align-items : center; 
} 
#ivhdd7 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i5127f {
text-align : center; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
position : absolute; 
left : 20px; 
padding : 0px 20px 0px 20px; 
height : 12vh; 
display : flex; 
justify-content : center; 
align-items : center; 
align-self : center; 
} 
#i6a797 {
color : black; 
height : 10vh; 
} 
#ir7fvj {
padding : 10px; 
min-height : 100px; 
} 
#izc21 {
padding : 10px; 
display : block; 
} 
#icmxa {
min-height : 100px; 
padding : 10px; 
margin : 25px 0px 0px 0px; 
} 
#iljwh {
padding : 10px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i9mif {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ib672 {
background-color : transparent; 
} 
.grupo-inputs {
display : flex; 
flex-direction : column; 
align-items : stretch; 
justify-content : space-around; 
} 
.label-input {
text-align : center; 
} 
.input-mixclo {
border : 1px solid #5e6164; 
padding : 5px 5px 5px 5px; 
align-items : center; 
} 
#ihqid {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
color : #ffffff; 
} 
#iye43 {
padding : 10px; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#i47yq {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
text-align : center; 
width : 56%; 
padding : 10px 10px 10px 10px; 
border-radius : 15px 15px 15px 15px; 
} 
#iod4j {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#ieqif {
min-height : 100px; 
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#iyu74 {
padding : 10px; 
width : 300px; 
} 
#i3lfk {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iefsx {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ios9o {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0 0 5px 0 black ; 
border-radius : 10px 10px 10px 10px; 
} 
#iekjv {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i123f {
color : black; 
width : 80px; 
} 
#idgg2 {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i07ml {
padding : 5px 5px; 
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
} 
.button-mixclo {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
color : #ffffff; 
width : 180px; 
text-align : center; 
border-radius : 10px 10px 10px 10px; 
} 
.button-mixclo:hover  {
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#126ead 0%, #126ead 100%); 
} 

  @media only screen and (max-width: 992px) {#ix7n {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#ix0scd {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#i0njf {
width : 80%; 
display : flex; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#i8hrnl {
width : 80%; 
display : flex; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#ij65 {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#icss0d {
width : 252.469px; 
}} 

  @media only screen and (max-width: 480px) {#ix7n {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ix0scd {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8hrnl {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i0njf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#irt2n {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#iqzlf {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i8ntp {
font-size : 14px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ij65 {
padding : 0px 5px 0px 5px; 
}} 

}
  