
#id6542de8ec239522da2e3ac60 {
  #i25m {
min-height : 100px; 
display : flex; 
} 
#i1au {
padding : 10px; 
min-height : 60px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 12vh; 
} 
#ib43 {
min-height : 100px; 
width : 90%; 
} 
#iq9l {
padding : 10px; 
min-height : 100px; 
width : 10%; 
display : flex; 
flex-direction : column; 
align-items : center; 
justify-content : flex-start; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 100vh; 
} 
#ievj {
padding : 10px; 
min-height : 100px; 
height : 88vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
} 
#iss9f {
color : black; 
margin : 10px 0 0 0; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ify7b {
width : 100%; 
display : block; 
} 
#i8eor {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iucyx {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i73es {
color : black; 
width : 75px; 
} 
#il6cc {
color : black; 
width : 45px; 
height : 45px; 
} 
#ijvrd {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9p3l {
padding : 10px; 
min-height : 300px; 
width : 100%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
flex-direction : column; 
} 
#i9sfh {
color : black; 
width : 107px; 
height : 107px; 
} 
#iq338 {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9obh {
color : black; 
width : 50px; 
height : 50px; 
} 
#i76os {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ixp2y {
color : black; 
width : 75px; 
} 
#irruz {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iyz7i {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ihmgh {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
height : 10vh; 
} 
#i8iqk {
padding : 10px; 
height : 68.18181610107422px; 
} 
#ikbwp {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#ivg2s {
color : black; 
width : 50px; 
} 
#ivoo4 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 10px 10px 10px 0px; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ipcv9 {
padding : 10px; 
display : block; 
font-size : 2rem; 
text-align : center; 
font-weight : 800; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ig0z1 {
display : block; 
} 
#isbjh {
padding : 10px; 
display : block; 
} 
#ixi4a {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iuapg {
font-family : Arial, sans-serif; 
} 
#ifj89 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#inpjt {
font-family : Arial, sans-serif; 
} 
#i6qs2 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ie7li {
font-family : Arial, sans-serif; 
} 
#iagil {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#i6bud {
font-family : Arial, sans-serif; 
} 
#im80i {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ic922 {
font-family : Arial, sans-serif; 
} 
#iqel7 {
font-family : Arial, sans-serif; 
} 
#icn1x {
font-family : Arial, sans-serif; 
} 
#i1l853 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iw71qg {
font-family : Arial, sans-serif; 
} 
#iy78en {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ifp4lc {
font-family : Arial, sans-serif; 
} 
#ip0q65 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ir3g8w {
font-family : Arial, sans-serif; 
} 
#iw24qv {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ixeaqj {
font-family : Arial, sans-serif; 
} 
#i9wfrd {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#iiac4f {
font-family : Arial, sans-serif; 
} 
#izt3lb {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iz4mg9 {
font-family : Arial, sans-serif; 
} 
#iox35k {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#injgjh {
font-family : Arial, sans-serif; 
} 
#i55s1w {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ifw4ab {
font-family : Arial, sans-serif; 
} 
#i19f08 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i2n0re {
font-family : Arial, sans-serif; 
} 
#i0fu5a {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i6vhev {
font-family : Arial, sans-serif; 
} 
#i07t45 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#igtg6f {
font-family : Arial, sans-serif; 
} 
#ihy0yl {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ivim9n {
font-family : Arial, sans-serif; 
} 
#iloe3g {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i95mda {
font-family : Arial, sans-serif; 
} 
#ii2wbk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ih1jjx {
font-family : Arial, sans-serif; 
} 
#i5w27f {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#it2uco {
font-family : Arial, sans-serif; 
} 
#ip8y4l {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#inuznw {
font-family : Arial, sans-serif; 
} 
#i8t8v7 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iiw8do {
font-family : Arial, sans-serif; 
} 
#ieh9uh {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ipcz98 {
font-family : Arial, sans-serif; 
} 
#iyxjaj {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#istizs {
font-family : Arial, sans-serif; 
} 
#i50ix2 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i10xeh {
font-family : Arial, sans-serif; 
} 
#i7rtkz {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i74i7i {
font-family : Arial, sans-serif; 
} 
#ibeuis {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#icz66u {
font-family : Arial, sans-serif; 
} 
#iiituk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i0w1lq {
font-family : Arial, sans-serif; 
} 
#ivzylu {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ia47dx {
font-family : Arial, sans-serif; 
} 
#i8npcr {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#ir0kzc {
font-family : Arial, sans-serif; 
} 
#iiisec {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i41pch {
font-family : Arial, sans-serif; 
} 
#iqztak {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iy33x8 {
font-family : Arial, sans-serif; 
} 
#iprrnj {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ic6qr3 {
font-family : Arial, sans-serif; 
} 
#i1yvux {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ijs471 {
font-family : Arial, sans-serif; 
} 
#idp8w4 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ig3hr1 {
font-family : Arial, sans-serif; 
} 
#i4zo77 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i6h71m {
font-family : Arial, sans-serif; 
} 
#iaf462 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#i507ok {
font-family : Arial, sans-serif; 
} 
#ijcnky {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iongll {
font-family : Arial, sans-serif; 
} 
#iiiobg {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ihp7tv {
font-family : Arial, sans-serif; 
} 
#iitz9h {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ikwamk {
font-family : Arial, sans-serif; 
} 
#ip2cul {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iu6hyg {
font-family : Arial, sans-serif; 
} 
#ii6uoq {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#ibokmi {
font-family : Arial, sans-serif; 
} 
#ijn1c3 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iw17ix {
font-family : Arial, sans-serif; 
} 
#icbmug {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i4az4n {
font-family : Arial, sans-serif; 
} 
#iknlej {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#i50znt {
font-family : Arial, sans-serif; 
} 
#ifvy5q {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iw0u8w {
font-family : Arial, sans-serif; 
} 
#i60nuz {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#irmo0n {
font-family : Arial, sans-serif; 
} 
#igdvb5 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iekvhn {
font-family : Arial, sans-serif; 
} 
#i6euts {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#izdx2a {
font-family : Arial, sans-serif; 
} 
#itgqxe {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#iq5z6y {
font-family : Arial, sans-serif; 
} 
#innn0l {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ibzmwh {
font-family : Arial, sans-serif; 
} 
#i6wp9r {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iwh7zg {
font-family : Arial, sans-serif; 
} 
#i138fx {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iei2xc {
font-family : Arial, sans-serif; 
} 
#icdxcy {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#itwmnb {
font-family : Arial, sans-serif; 
} 
#ipxdcb {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ikw566 {
font-family : Arial, sans-serif; 
} 
#i2rizu {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#imdsdp {
font-family : Arial, sans-serif; 
} 
#i101dh {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#ios35h {
font-family : Arial, sans-serif; 
} 
#i4cjsl {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i6b1ug {
font-family : Arial, sans-serif; 
} 
#ie9c1d {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i6qizo {
font-family : Arial, sans-serif; 
} 
#i2cjg5 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#i6gxw2 {
font-family : Arial, sans-serif; 
} 
#ignccc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ittoxe {
font-family : Arial, sans-serif; 
} 
#idmoiq {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i1ml5r {
font-family : Arial, sans-serif; 
} 
#i53w0n {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#ibq0l9 {
font-family : Arial, sans-serif; 
} 
#ia6ysc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ia754b {
font-family : Arial, sans-serif; 
} 
#iyc0jd {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ilqg02 {
font-family : Arial, sans-serif; 
} 
#igll7q {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ic4wyj {
font-family : Arial, sans-serif; 
} 
#i1oy9c {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ijbmae {
font-family : Arial, sans-serif; 
} 
#iss0sl {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#icrkql {
font-family : Arial, sans-serif; 
} 
#i3r5ba {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#in4jgz {
font-family : Arial, sans-serif; 
} 
#i36jeh {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i0e6vh {
font-family : Arial, sans-serif; 
} 
#ijsm9g {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i9vnz9 {
font-family : Arial, sans-serif; 
} 
#ie8k03 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#i9ciiy {
font-family : Arial, sans-serif; 
} 
#ijq6h2 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i1924h {
font-family : Arial, sans-serif; 
} 
#ipyevm {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#ivvx5g {
font-family : Arial, sans-serif; 
} 
#ih9khk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i92lqi {
font-family : Arial, sans-serif; 
} 
#ihsul4 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#ik7c7f {
font-family : Arial, sans-serif; 
} 
#i1g2dg {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iaarbg {
font-family : Arial, sans-serif; 
} 
#ied1wx {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iceg14 {
font-family : Arial, sans-serif; 
} 
#ilgddl {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iarhq7 {
font-family : Arial, sans-serif; 
} 
#io7kdc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iplwzy {
font-family : Arial, sans-serif; 
} 
#i7iqjb {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : center; 
} 
#iqkqg8 {
font-family : Arial, sans-serif; 
} 
#irlgzp {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iztcjz {
font-family : Arial, sans-serif; 
} 
#izr985 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ihb95d {
font-family : Arial, sans-serif; 
} 

  @media only screen and (max-width: 992px) {#ib43 {
width : 85%; 
}} 
@media only screen and (max-width: 992px) {#iq9l {
width : 15%; 
}} 

  
}
  