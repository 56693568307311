
#id65023d9b925546452564cb3f {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
align-items : center; 
} 
#i3vl {
color : black; 
width : 142px; 
} 
#ij65 {
min-height : 88vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
justify-content : flex-start; 
display : flex; 
align-items : stretch; 
border : 1px solid #5e6164; 
padding : 0px 0px 0px 0; 
flex-wrap : wrap; 
align-self : stretch; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
.checkboxMain {
width : 300px; 
padding : 0px 0px 0px 0px; 
} 
.checkboxContainer {
display : block; 
position : absolute; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
float : none; 
right : 0px; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
left : 0px; 
padding : 0 0px 0px 0px; 
margin : 10px 0px 0px 0px; 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
.test {
padding : 0 0px 0px 0px; 
} 
.fullWidth {
width : 100%; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#idxbx {
display : block; 
} 
#iieo {
padding : 10px; 
min-height : 100px; 
width : 80%; 
border-radius : 0px 0px 0px 0; 
} 
#i29ru {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#i59nf {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#iffm5 {
flex-wrap : wrap; 
} 
#id0p7 {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#i6ikd {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#izk0k {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#i58ml {
color : black; 
} 
#incag {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i2o52 {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#ihow92 {
padding : 10px; 
min-height : 100px; 
} 
#i4q4qw {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border-radius : 20px 20px 20px 20px; 
display : flex; 
flex-direction : column; 
align-items : center; 
justify-content : flex-start; 
align-self : auto; 
border : 4px solid black; 
} 
#irxlfd {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#30d158 0%, #30d158 100%); 
border-radius : 20px 20px 20px 20px; 
width : 100%; 
} 
#if643m {
padding : 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 20px 20px 20px 20px; 
width : 100%; 
box-shadow : 5px 12px 25px -8px black ; 
border : 4px solid black; 
} 
#if9all {
min-height : 0px; 
height : 0px; 
margin : 10px 0px 10px 0px; 
border : 2px solid black; 
width : 70%; 
} 
#iu364h {
display : block; 
float : none; 
text-align : center; 
} 
#i0o2rh {
padding : 2px 0px 2px 0px; 
display : block; 
color : white; 
text-align : center; 
} 
#iddz9v {
padding : 2px 0px 2px 0px; 
display : block; 
color : white; 
text-align : center; 
} 
#ibwcdk {
padding : 2px 0px 2px 0px; 
display : block; 
color : white; 
text-align : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i2rp5q {
text-align : center; 
display : flex; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
position : absolute; 
height : 12vh; 
left : 20px; 
padding : 0px 20px 0px 20px; 
justify-content : center; 
align-items : center; 
align-self : center; 
} 
#i3u81m {
color : black; 
height : 10vh; 
} 
#igbicw {
padding : 2px 0px 2px 0px; 
display : block; 
color : white; 
text-align : center; 
} 
#ino9i {
color : black; 
} 
#irj7j {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#iu7fh {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ibkif {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#iq8g9 {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#i4kft {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#iiexj {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#ixdik {
color : black; 
} 
#i1cjw {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#i348z {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i211e {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#ibpdi {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#iuoakz {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#icg36o {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#inx4kf {
color : black; 
} 
#id1unb {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#i5fmqb {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ieawoi {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#ikwro1 {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#il5nuj {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#i6bdqa {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#iz83ck {
color : black; 
} 
#ioadht {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#ivr1ut {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i48t35 {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#igbvtj {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#iq0dsz {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#ic22jk {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#ivfxwf {
color : black; 
} 
#ixj8z3 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#itaax6 {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i05bom {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#i2ymvh {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#ik06ye {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#ieb5lc {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 

  @media only screen and (max-width: 992px) {#ij65 {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#i59nf {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iieo {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#i29ru {
width : 30%; 
}} 
@media only screen and (max-width: 992px) {#iiexj {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#icg36o {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#i6bdqa {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#ic22jk {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#ieb5lc {
width : 50%; 
}} 

  @media only screen and (max-width: 480px) {#ij65 {
padding : 0px 5px 0px 5px; 
flex-wrap : wrap-reverse; 
}} 
@media only screen and (max-width: 480px) {#iieo {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i59nf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i29ru {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iiexj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#icg36o {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i6bdqa {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ic22jk {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ieb5lc {
width : 100%; 
}} 

}
  