
#id6667350f914da0d427fd81df {
  #iw4j {
min-height : 100px; 
font-family : Montserrat; 
} 
#ips2 {
padding : 10px 5% 10px 5%; 
min-height : 10px; 
display : flex; 
justify-content : space-between; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#ihk8 {
padding : 10px 5% 10px 5%; 
min-height : 80vh; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
} 
#i3dh {
padding : 120px 5% 120px 5%; 
min-height : 48.39996337890625px; 
} 
#icohi {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
color : #ffffff; 
padding : 10px 10px 10px 10px; 
} 
#itmdc {
padding : 10px; 
min-height : 53.199981689453125px; 
width : 210.19992065429688px; 
display : flex; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : flex-start; 
} 
#ismeq {
color : black; 
width : 210px; 
min-height : 10auto; 
} 
#it6xg {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#if5pi {
padding : 10px; 
width : 50%; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#ip3sl {
padding : 10px; 
display : block; 
font-size : 82px; 
font-weight : 600; 
} 
#i803s {
padding : 10px; 
display : block; 
font-size : 20px; 
min-height : 10px; 
} 
#iz9d4 {
color : black; 
width : 818px; 
min-height : 10auto; 
border-radius : 25px 25px 25px 25px; 
border : 3px solid black; 
} 
#igqyi {
padding : 10px; 
display : block; 
font-size : 55px; 
font-weight : 600; 
text-align : center; 
margin : 10px 0px 0px 0px; 
} 
#iyijx2 {
padding : 10px; 
display : block; 
font-size : 55px; 
font-weight : 600; 
text-align : center; 
} 
#il6v7o {
min-height : 100px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iou1pv {
padding : 10px; 
} 
#izq2dp {
padding : 10px; 
display : block; 
text-align : center; 
} 
#iicawo {
padding : 10px; 
display : block; 
text-align : center; 
font-size : 18px; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iok6z3 {
display : none; 
} 
#ipazry {
font-size : 3.5rem; 
margin-bottom : 1rem; 
color : rgb(0, 0, 0); 
font-family : Arial, Helvetica, sans-serif; 
font-weight : 700; 
text-align : left; 
width : 508.88697558593753px; 
} 
#iqulzk {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
justify-content : center; 
width : 554.4870122070313px; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i894r1 {
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
} 
.button-negro {
border-radius : 5px 5px 5px 5px; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
padding : 10px 25px 10px 25px; 
margin : 0px 400px 0px 0px; 
text-align : center; 
text-decoration : none; 
} 
.button-negro:hover  {
color : #ffffff; 
} 
#inczd9 {
padding : 10px 10px 10px 10px; 
display : block; 
font-size : 18px; 
text-align : justify; 
width : 1278px; 
justify-content : flex-start; 
align-items : stretch; 
align-self : auto; 
margin : 0px 0px 0px 120px; 
} 
#ivn0rj {
min-height : 100px; 
} 
.card {
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.37) ; 
border-radius : 10px 10px 10px 10px; 
} 
.card.beneficios {
margin : 70px 0 0 0; 
width : 530px; 
padding : 0px 0 50px 0px; 
min-height : 10px; 
} 
#if39u3 {
padding : 10px; 
min-height : 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 10px 10px 0px 0px; 
} 
#i45acb {
padding : 10px; 
min-height : 239.99996948242188px; 
display : flex; 
justify-content : center; 
} 
#inihm4 {
padding : 10px; 
display : block; 
font-size : 35px; 
font-weight : 700; 
text-align : center; 
} 
.listado {
justify-content : flex-start; 
} 
#ifg5ok {
padding : 10px; 
min-height : 221.59994506835938px; 
display : flex; 
justify-content : center; 
margin : 0px 0px 0 0px; 
} 
#i402eg {
min-height : 100px; 
} 
.card.comision {
width : 530px; 
min-height : 264.8000183105469px; 
padding : 0 0 10px 0; 
} 
#irwzmu {
padding : 10px; 
display : block; 
font-weight : 700; 
font-size : 1.5rem; 
text-align : center; 
min-height : 10auto; 
margin : 0px 0px 0px 0px; 
} 
.card.contacto {
margin : 70px 0px 100px 0px; 
padding : 10px 10px 10px 10px; 
width : 610.9998779296875px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#irny3g {
min-height : 100px; 
padding : 10px; 
} 
#if5wu3 {
padding : 10px; 
} 
#i7wydp {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iaivkn {
background-color : transparent; 
} 
#i8e7dl {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#itc009 {
background-color : transparent; 
} 
#ij1c4p {
font-weight : 700; 
} 
#ieud0c {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
.input {
padding : 10px 10px 10px 10px; 
border : 1px solid black; 
margin : 1rem 0rem 1rem 0rem; 
width : 100%; 
font-weight : 400; 
font-family : Arial, Helvetica, sans-serif; 
} 
.button-negro.botonful {
width : 30%; 
float : center; 
text-align : center; 
margin : 0px 0px 0px 190px; 
} 
#itexsa {
text-align : center; 
font-weight : 600; 
} 
#im9zh1 {
text-decoration : none; 
font-weight : 600; 
text-align : center; 
} 
#ik25ps {
font-weight : 700; 
} 
#ijfni9 {
padding : 10px; 
display : block; 
text-align : center; 
font-size : 18px; 
} 
#iasc88 {
padding : 10px; 
min-height : 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 10px 10px 0px 0px; 
} 
#iikwdr {
padding : 10px; 
display : block; 
font-size : 35px; 
font-weight : 700; 
text-align : center; 
width : 513.5251342773438px; 
margin : 0px 0 0px 0px; 
} 
#i8666j {
color : black; 
width : 30.4000244140625px; 
min-height : 10auto; 
} 
#itnnb6 {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#iq0ljb {
padding : 10px; 
display : flex; 
align-items : center; 
} 
#ibvnx4 {
padding : 10px; 
display : block; 
font-weight : 400; 
float : none; 
text-align : left; 
width : 451.075048828125px; 
} 
#ipwk2h {
padding : 10px; 
min-height : 100px; 
} 
#iere45 {
min-height : 142px; 
display : flex; 
align-items : center; 
padding : 10px 10px 10px 10px; 
justify-content : center; 
} 
#ilr1xw {
color : black; 
width : 30.4000244140625px; 
min-height : 10auto; 
} 
#iuoucx {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#igha3f {
padding : 10px; 
display : flex; 
align-items : center; 
} 
#ib43hi {
padding : 10px; 
display : block; 
font-weight : 400; 
float : none; 
text-align : left; 
width : 451.075048828125px; 
} 
#im6yvr {
padding : 10px; 
min-height : 100px; 
} 
#iq76ui {
min-height : 142px; 
display : flex; 
align-items : center; 
padding : 10px 10px 10px 10px; 
justify-content : center; 
} 
#iamojv {
color : black; 
width : 30.4000244140625px; 
min-height : 10auto; 
} 
#ihgu5b {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#i0lx9g {
padding : 10px; 
display : flex; 
align-items : center; 
} 
#ictur7 {
padding : 10px; 
display : block; 
font-weight : 400; 
float : none; 
text-align : left; 
width : 451.075048828125px; 
} 
#i0232x {
padding : 10px; 
min-height : 100px; 
} 
#ivealj {
min-height : 142px; 
display : flex; 
align-items : center; 
padding : 10px 10px 10px 10px; 
justify-content : center; 
} 
#i62zee {
color : black; 
width : 30.4000244140625px; 
min-height : 10auto; 
} 
#itd2pp {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#ilzmny {
padding : 10px; 
display : flex; 
align-items : center; 
} 
#ieqxum {
padding : 10px; 
display : block; 
font-weight : 400; 
float : none; 
text-align : left; 
width : 451.075048828125px; 
} 
#ihx88g {
padding : 10px; 
min-height : 100px; 
} 
#inuxel {
min-height : 142px; 
display : flex; 
align-items : center; 
padding : 10px 10px 10px 10px; 
justify-content : center; 
} 
#iboty1 {
color : black; 
width : 30.4000244140625px; 
min-height : 10auto; 
} 
#igqagk {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#impl3g {
padding : 10px; 
display : flex; 
align-items : center; 
} 
#iqhhr5 {
padding : 10px; 
display : block; 
font-weight : 400; 
float : none; 
text-align : left; 
width : 451.075048828125px; 
} 
#ih6dpf {
padding : 10px; 
min-height : 100px; 
} 
#isnlda {
min-height : 142px; 
display : flex; 
align-items : center; 
padding : 10px 10px 10px 10px; 
justify-content : center; 
} 
#imrgtj {
color : black; 
width : 30.4000244140625px; 
min-height : 10auto; 
} 
#ixiso7 {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#i34m0y {
padding : 10px; 
display : flex; 
align-items : center; 
} 
#i2rc6j {
padding : 10px; 
display : block; 
font-weight : 400; 
float : none; 
text-align : left; 
width : 451.075048828125px; 
} 
#icpcmy {
padding : 10px; 
min-height : 100px; 
} 
#ig4oya {
min-height : 142px; 
display : flex; 
align-items : center; 
padding : 10px 10px 10px 10px; 
justify-content : center; 
} 

  @media only screen and (max-width: 992px) {#ip3sl {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {#i803s {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#it6xg {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#if5pi {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ips2 {
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 992px) {#inczd9 {
font-size : 20px; 
min-height : 59.20001220703125px; 
width : 574.5999755859375px; 
text-align : center; 
margin : 0px 0px 0px 95px; 
}} 
@media only screen and (max-width: 992px) {#inihm4 {
width : auto; 
}} 
@media only screen and (max-width: 992px) {#iikwdr {
width : auto; 
}} 
@media only screen and (max-width: 992px) {#itmdc {
width : 146.98696337890624px; 
min-height : 10px; 
}} 
@media only screen and (max-width: 992px) {.card.beneficios {
width : 70%; 
}} 

  @media only screen and (max-width: 480px) {#itmdc {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iok6z3 {
color : black; 
display : block; 
width : 114px; 
min-height : 55.599998474121094auto; 
margin : 0px 0px 0px 31px; 
}} 
@media only screen and (max-width: 480px) {#ip3sl {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#iz9d4 {
width : 310px; 
height : 180px; 
min-height : 10auto; 
}} 
@media only screen and (max-width: 480px) {#igqyi {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#iyijx2 {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#il6v7o {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {.card.beneficios {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#i45acb {
padding : 0px 10px 0px 10px; 
}} 
@media only screen and (max-width: 480px) {#ipazry {
font-size : 2rem; 
width : 258.7999267578125px; 
}} 
@media only screen and (max-width: 480px) {#i803s {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#inihm4 {
font-size : 25px; 
margin : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#iikwdr {
font-size : 25px; 
margin : 0px 0px 0px 0px; 
width : auto; 
}} 
@media only screen and (max-width: 480px) {#inczd9 {
margin : 0px 0px 0px 0px; 
text-align : left; 
width : auto; 
padding : 15px 15px 15px 15px; 
}} 
@media only screen and (max-width: 480px) {.button-negro.botonful {
margin : 0px 0px 0px 0px; 
width : 60%; 
}} 
@media only screen and (max-width: 480px) {#iqulzk {
width : 153.20001220703125px; 
}} 
@media only screen and (max-width: 480px) {.button-negro {
float : none; 
position : relative; 
margin : 0px 0px 0px -6px; 
}} 
@media only screen and (max-width: 480px) {#irwzmu {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#im9zh1 {
position : static; 
margin : 0px 0px 0px 0; 
}} 
@media only screen and (max-width: 480px) {.card.contacto {
width : 310px; 
min-height : 86.39999389648438px; 
}} 
@media only screen and (max-width: 480px) {#itnnb6 {
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ibvnx4 {
min-height : 10auto; 
font-size : 14px; 
width : 80%; 
}} 
@media only screen and (max-width: 480px) {#iere45 {
min-height : 120px; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#if5wu3 {
padding : 0px 10px 0px 10px; 
}} 
@media only screen and (max-width: 480px) {#iuoucx {
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ib43hi {
width : 80%; 
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iq76ui {
min-height : 120px; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#ihgu5b {
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ictur7 {
width : 80%; 
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ivealj {
min-height : 120px; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#itd2pp {
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ieqxum {
width : 80%; 
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#inuxel {
min-height : 120px; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#igqagk {
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iqhhr5 {
width : 80%; 
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#isnlda {
min-height : 120px; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#impl3g {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#ixiso7 {
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#i2rc6j {
width : 80%; 
min-height : 10auto; 
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ig4oya {
min-height : 120px; 
flex-direction : column; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#i34m0y {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#i3dh {
padding : 120px 0% 120px 0%; 
}} 
@media only screen and (max-width: 480px) {#iq0ljb {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {.descripcionbeneficios {
margin : 0 0 0 10%; 
}} 
@media only screen and (max-width: 480px) {.card.comision {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#i0lx9g {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#ilzmny {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#igha3f {
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#irny3g {
padding : 0px 10px 0px 10px; 
}} 
@media only screen and (max-width: 480px) {#inmvli {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
}} 

}
  