
#id651b4b7d925546452564e32a {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
align-items : center; 
} 
#i3vl {
color : black; 
width : 142px; 
} 
#ij65 {
min-height : 88vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
justify-content : flex-start; 
display : flex; 
align-items : stretch; 
border : 1px solid #5e6164; 
padding : 0px 0px 0px 0; 
flex-wrap : wrap; 
align-self : stretch; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
.checkboxMain {
width : 300px; 
padding : 0px 0px 0px 0px; 
} 
.checkboxContainer {
display : block; 
position : absolute; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
float : none; 
right : 0px; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
left : 0px; 
padding : 0 0px 0px 0px; 
margin : 10px 0px 0px 0px; 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
.test {
padding : 0 0px 0px 0px; 
} 
.fullWidth {
width : 100%; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#idxbx {
display : block; 
} 
#iieo {
padding : 10px; 
min-height : 100px; 
width : 100%; 
border-radius : 0px 0px 0px 0; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#i59nf {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#iffm5 {
flex-wrap : wrap; 
} 
#id0p7 {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#i6ikd {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#izk0k {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#i58ml {
color : black; 
} 
#incag {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i2o52 {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i2rp5q {
text-align : center; 
display : flex; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
position : absolute; 
height : 12vh; 
left : 20px; 
padding : 0px 20px 0px 20px; 
justify-content : center; 
align-items : center; 
align-self : center; 
} 
#i3u81m {
color : black; 
height : 10vh; 
} 
#iut4pq {
font-weight : 400; 
} 
#ilt6wm {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#iapmzw {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ipd7ig {
padding : 10px; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#i83qvt {
border : 4px solid black; 
border-radius : 10px 10px 10px 10px; 
padding : 5px 10px 5px 10px; 
font-weight : 700; 
color : #000000; 
transition : all 1500ms linear; 
} 
#in0ry {
font-weight : 400; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
#i4olc {
padding : 10px; 
} 
#iy5bh {
padding : 10px; 
} 
#ivhi1 {
text-align : center; 
} 
.swiper.mySwiper {
height : 100%; 
width : 100%; 
padding : 0px 0px 0px 0; 
} 
#i83qvt:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#ij3rbd {
color : black; 
} 
#ivsb4r {
padding : 10px; 
} 
#i1j53k {
text-align : center; 
} 
#i6l8tf {
padding : 10px; 
} 
#iyh5j1 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#iqjt4x {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#iyqj2j {
font-weight : 400; 
} 
#ifspym {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#iz93q3 {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ijw9i5 {
font-weight : 400; 
} 
#il7mnq {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#iyd27t {
border : 4px solid black; 
border-radius : 10px 10px 10px 10px; 
padding : 5px 10px 5px 10px; 
font-weight : 700; 
color : #000000; 
transition : all 1500ms linear; 
} 
#iyd27t:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#i3pdsz {
padding : 10px; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#imj3y3 {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#ia80bk {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#iqt1j1 {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#ipi2eg {
color : black; 
} 
#i1h4kg {
padding : 10px; 
} 
#i03peo {
text-align : center; 
} 
#i32skc {
padding : 10px; 
} 
#iv65g1 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#iy7opj {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#img2vu {
font-weight : 400; 
} 
#i341nr {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#iethpr {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i9r5m6 {
font-weight : 400; 
} 
#i9n58d {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#ios82j {
border : 4px solid black; 
border-radius : 10px 10px 10px 10px; 
padding : 5px 10px 5px 10px; 
font-weight : 700; 
color : #000000; 
transition : all 1500ms linear; 
} 
#ios82j:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#i2p7hl {
padding : 10px; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#iwdyke {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#ikk86e {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#izj4sw {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#imhlfz {
color : black; 
} 
#ij5zxu {
padding : 10px; 
} 
#iz5noj {
text-align : center; 
} 
#iigi3i {
padding : 10px; 
} 
#ivoa6l {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#i040b2 {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#im46kc {
font-weight : 400; 
} 
#ib5oth {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i2nuux {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ifdbm8 {
font-weight : 400; 
} 
#iws7ck {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#i542ll {
border : 4px solid black; 
border-radius : 10px 10px 10px 10px; 
padding : 5px 10px 5px 10px; 
font-weight : 700; 
color : #000000; 
transition : all 1500ms linear; 
} 
#i542ll:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#ifsgri {
padding : 10px; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#imo62f {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#ijjfz2 {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#iarrfo {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#iexnra {
color : black; 
} 
#i9tvwh {
padding : 10px; 
} 
#ijcnpi {
text-align : center; 
} 
#iq61m7 {
padding : 10px; 
} 
#ih9we8 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 4px solid #000000; 
border-radius : 20px 20px 0px 0px; 
height : 15rem; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#ix4h5f {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ipr1ry {
font-weight : 400; 
} 
#iyls7p {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#ilq90j {
padding : 5px 0px 5px 0px; 
display : block; 
text-align : center; 
} 
#i7vhdh {
font-weight : 400; 
} 
#ivnd78 {
padding : 0px 0px 0px 0px; 
display : block; 
text-align : center; 
} 
#ippw5k {
border : 4px solid black; 
border-radius : 10px 10px 10px 10px; 
padding : 5px 10px 5px 10px; 
font-weight : 700; 
color : #000000; 
transition : all 1500ms linear; 
} 
#ippw5k:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#iud7nj {
padding : 10px; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#i0m9az {
padding : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffd60a 0%, #ffd60a 100%); 
border-radius : 0px 0px 20px 20px; 
border : 4px solid black; 
} 
#itprc1 {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 15rem; 
border-radius : 20px 20px 20px 20px; 
box-shadow : 5px 12px 25px -8px black ; 
position : relative; 
} 
#i7crag {
width : 33%; 
padding : 2rem 2rem 2rem 2rem; 
display : flex; 
align-items : center; 
justify-content : center; 
position : relative; 
} 
#imnu59 {
padding : 10px; 
min-height : 100px; 
} 
#ichskd {
padding : 10px; 
display : block; 
color : white; 
font-weight : 800; 
font-size : 4rem; 
text-align : center; 
} 
#iprmbx {
padding : 10px; 
display : block; 
font-weight : 600; 
font-size : 1.5rem; 
color : white; 
text-align : center; 
} 

  @media only screen and (max-width: 992px) {#ij65 {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#i59nf {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iieo {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#iqt1j1 {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#izj4sw {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iarrfo {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#i7crag {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#ichskd {
font-size : 3rem; 
}} 

  @media only screen and (max-width: 480px) {#ij65 {
padding : 0px 5px 0px 5px; 
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#iieo {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i59nf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqt1j1 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#izj4sw {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iarrfo {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i7crag {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i2rp5q {
left : 0px; 
}} 
@media only screen and (max-width: 480px) {#ichskd {
font-size : 1.8rem; 
}} 
@media only screen and (max-width: 480px) {#i8j5 {
justify-content : flex-end; 
}} 

}
  