
#id64f0e1f292554645256498e5 {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
align-items : center; 
} 
#i3vl {
color : black; 
width : 142px; 
} 
#ij65 {
min-height : 100vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
justify-content : center; 
display : flex; 
align-items : flex-start; 
flex-direction : row; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
.checkboxMain {
width : 300px; 
padding : 0px 0px 0px 0px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
.test {
padding : 0 0px 0px 0px; 
} 
.fullWidth {
width : 100%; 
} 
#iylc {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
width : 707px; 
margin : 70px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
box-shadow : 1px 1px 10px 6px rgba(0,0,0,0.09) ; 
border : 1px solid black; 
} 
#i6op {
padding : 10px; 
display : block; 
font-size : 25px; 
color : #34c759; 
font-weight : 800; 
font-family : Verdana, Geneva, sans-serif; 
text-align : center; 
} 
#ittys {
padding : 10px; 
min-height : 100px; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 18px; 
} 
#ijwdc {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iigmf {
padding : 10px; 
width : 50%; 
} 
#idhpi {
padding : 10px; 
width : 50%; 
} 
#ihlsu {
padding : 10px; 
display : block; 
text-align : right; 
} 
#imaa6 {
padding : 10px; 
display : block; 
} 
#igdp9 {
padding : 10px; 
display : block; 
text-align : right; 
} 
#ia44n {
padding : 10px; 
width : 50%; 
} 
#ietuj {
padding : 10px; 
display : block; 
} 
#ipc7s {
padding : 10px; 
width : 50%; 
} 
#ihv65 {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iryht {
padding : 10px; 
display : block; 
text-align : right; 
} 
#ioj6b {
padding : 10px; 
width : 50%; 
} 
#ivnwf {
padding : 10px; 
display : block; 
} 
#iqimt {
padding : 10px; 
width : 50%; 
} 
#i65up {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#izgqe {
padding : 10px; 
display : block; 
text-align : right; 
} 
#ijnbt {
padding : 10px; 
width : 50%; 
} 
#idzrd {
padding : 10px; 
display : block; 
} 
#i9lmm {
padding : 10px; 
width : 50%; 
} 
#iqnbs {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#ii85h {
padding : 10px; 
display : block; 
text-align : right; 
} 
#ir42k {
padding : 10px; 
width : 50%; 
} 
#ieatd {
padding : 10px; 
display : block; 
} 
#ihpxh {
padding : 10px; 
width : 50%; 
} 
#i04jo {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i4f68 {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 22px; 
} 
#ig68r {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ir0og {
display : block; 
width : auto; 
height : auto; 
} 
#iwct3 {
padding : 10px; 
min-height : 100px; 
font-family : Verdana, Geneva, sans-serif; 
} 
#in0o1v {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ify10o {
padding : 10px 15px 10px 15px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
cursor : pointer; 
color : #fff; 
__background-type : color; 
border-radius : 10px 10px 10px 10px; 
} 
#im4v9s {
font-weight : 800; 
font-family : Verdana, Geneva, sans-serif; 
} 
#i6ath {
text-align : center; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
position : absolute; 
left : 20px; 
display : flex; 
justify-content : center; 
align-items : center; 
align-self : center; 
height : 12vh; 
padding : 0px 20px 0px 20px; 
} 
#ia8ak {
color : black; 
height : 10vh; 
} 

  @media only screen and (max-width: 992px) {#iylc {
width : 80%; 
}} 

  @media only screen and (max-width: 480px) {#iylc {
width : 95%; 
}} 
@media only screen and (max-width: 480px) {#ig68r {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#i6op {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#ittys {
font-size : 14px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ijwdc {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#iqnbs {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i65up {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i04jo {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ihv65 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i4f68 {
font-size : 18px; 
}} 

}
  