
#id64f0e9e79255464525649b2f {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
align-items : center; 
} 
#i3vl {
color : black; 
width : 142px; 
} 
#ij65 {
min-height : 100vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
justify-content : space-between; 
display : flex; 
align-items : flex-start; 
border : 1px solid #5e6164; 
padding : 0px 15px 0px 15px; 
flex-wrap : wrap; 
} 
#ix7n {
padding : 0px 0px 0px 0; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
margin : 2.5rem 0px 0px 0px; 
box-shadow : 1px 3px 20px -4px black ; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#iosji {
padding : 10px; 
min-height : 100px; 
} 
#irt2n {
padding : 30px 10px 30px 10px; 
display : flex; 
float : center; 
justify-content : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 25px; 
color : black; 
font-weight : 400; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ix93k {
min-height : 100px; 
padding : 0 10px 10px 10px; 
margin : 25px 0 0 0; 
} 
#iuxmg {
padding : 0 10px 10px 10px; 
display : flex; 
flex-direction : column; 
height : 465.3869145507813px; 
} 
#ifct8 {
padding : 0 10px 10px 10px; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
align-self : auto; 
align-items : center; 
label-parent-flex : 0; 
margin : 0px 0px 0 0px; 
} 
#iaxa9 {
padding : 0 10px 10px 10px; 
display : flex; 
flex-direction : column; 
align-items : center; 
margin : 0px 0px 0 0px; 
} 
#i4q9t {
padding : 0px 10px 10px 10px; 
display : flex; 
flex-direction : column; 
align-items : center; 
height : 67.99995727539063px; 
margin : 0px 0px 0 0px; 
} 
#i9ust {
font-family : Verdana, Geneva, sans-serif; 
font-size : 0.9rem; 
color : #5e6164; 
} 
#i3j5l {
border : 1px solid #5e6164; 
margin : -20px 0px 0px 0px; 
} 
#io36a {
font-family : Verdana, Geneva, sans-serif; 
font-size : 0.9rem; 
color : #5e6164; 
} 
#i2q5e {
border : 1px solid #5e6164; 
margin : -20px 0px 0px 0px; 
} 
#ihkb1 {
font-family : Verdana, Geneva, sans-serif; 
font-size : 0.9rem; 
color : #5e6164; 
position : relative; 
} 
#iy239 {
border : 1px solid #5e6164; 
position : relative; 
padding : 0px 0px 0px 0px; 
margin : -20px 0px 0px 0px; 
width : 100%; 
} 
#iwis4 {
padding : 0 10px 10px 10px; 
flex-direction : column; 
align-items : center; 
display : flex; 
} 
.inputContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#isqwd {
margin : 0px 0px 5px 0px; 
} 
#i1lhd {
border : 1px solid #5e6164; 
} 
#i8ayc {
margin : 0px 0px 5px 0px; 
} 
#i8m2c {
border : 1px solid #5e6164; 
} 
#i2wrs {
padding : 0 10px 10px 10px; 
flex-direction : column; 
align-items : center; 
display : flex; 
} 
#iqqb1 {
margin : 0px 0px 5px 0px; 
} 
#iypwn {
border : 1px solid #5e6164; 
} 
#i6irs {
padding : 0 10px 10px 10px; 
flex-direction : column; 
align-items : center; 
display : flex; 
} 
#i5u8d {
height : 0px; 
border : 1px solid black; 
box-shadow : 0px 5px 10px 0px black ; 
} 
.checkboxMain {
width : 300px; 
padding : 0px 0px 0px 0px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
.test {
padding : 0 0px 0px 0px; 
} 
.fullWidth {
width : 100%; 
} 
#iioz4 {
padding : 10px; 
min-height : 78px; 
flex-direction : column; 
display : flex; 
align-self : center; 
width : 415px; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#idxbx {
display : block; 
} 
#i0njf {
padding : 10px; 
min-height : 100px; 
width : 40%; 
} 
#iqzlf {
padding : 30px 10px 30px 10px; 
display : flex; 
float : center; 
justify-content : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 25px; 
color : black; 
font-weight : 400; 
} 
#iy57p {
height : 0px; 
border : 1px solid black; 
box-shadow : 0px 5px 10px 0px black ; 
} 
#ihktr5 {
padding : 10px; 
min-height : 100px; 
} 
#ix0scd {
padding : 0px 0px 0px 0; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
margin : 2.5rem 0px 0px 0px; 
box-shadow : 1px 3px 20px -4px black ; 
} 
#i8hrnl {
padding : 10px; 
min-height : 100px; 
width : 40%; 
} 
#i8ntp {
padding : 10px; 
min-height : 100px; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 18px; 
} 
#inywf {
padding : 10px; 
display : flex; 
align-items : center; 
flex-wrap : wrap; 
} 
#i658l {
padding : 10px; 
width : 50%; 
} 
#i8z0s {
padding : 10px; 
width : 50%; 
} 
#i8qh6 {
padding : 10px; 
display : block; 
font-weight : 700; 
} 
#ibxk7 {
padding : 10px; 
display : block; 
} 
#ibnr7 {
padding : 10px; 
display : block; 
font-weight : 700; 
} 
#i3fma {
padding : 10px; 
width : 50%; 
} 
#ioog3 {
padding : 10px; 
display : block; 
} 
#iwlgl {
padding : 10px; 
width : 50%; 
} 
#i22xj {
padding : 10px; 
display : flex; 
align-items : center; 
flex-wrap : wrap; 
} 
#ijzgf {
padding : 10px; 
display : block; 
font-weight : 700; 
} 
#in6fec {
padding : 10px; 
width : 50%; 
} 
#iiyg7e {
padding : 10px; 
display : block; 
} 
#izlm47 {
padding : 10px; 
width : 50%; 
} 
#iinous {
padding : 10px; 
display : flex; 
align-items : center; 
flex-wrap : wrap; 
} 
#irt32n {
padding : 10px; 
display : block; 
font-weight : 700; 
} 
#i6773b {
padding : 10px; 
width : 50%; 
} 
#i5ddht {
padding : 10px; 
display : block; 
} 
#irsmec {
padding : 10px; 
width : 50%; 
} 
#i7c9b2 {
padding : 10px; 
display : flex; 
align-items : center; 
flex-wrap : wrap; 
} 
#iyvdus {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#iru89x {
width : 58.1%; 
display : flex; 
font-family : Verdana, Geneva, sans-serif; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 15px 15px 15px 15px; 
justify-content : space-around; 
align-items : center; 
} 
#igamwj {
padding : 10px; 
display : block; 
} 
#i24mx4 {
padding : 10px; 
display : block; 
} 
#icss0d {
width : 58.1%; 
display : flex; 
font-family : Verdana, Geneva, sans-serif; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 15px 15px 15px 15px; 
justify-content : space-around; 
align-items : center; 
} 
#ivhdd7 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#ijh9xn {
padding : 10px; 
display : block; 
} 
#iihz8e {
width : 58.1%; 
display : flex; 
font-family : Verdana, Geneva, sans-serif; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
border-radius : 15px 15px 15px 15px; 
justify-content : space-around; 
align-items : center; 
} 
#in730h {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i5127f {
text-align : center; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
position : absolute; 
left : 20px; 
padding : 0px 20px 0px 20px; 
height : 12vh; 
display : flex; 
justify-content : center; 
align-items : center; 
align-self : center; 
} 
#i6a797 {
color : black; 
height : 10vh; 
} 

  @media only screen and (max-width: 992px) {#ix7n {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#ix0scd {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#i0njf {
width : 80%; 
display : flex; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#i8hrnl {
width : 80%; 
display : flex; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#ij65 {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#iru89x {
width : 246.469px; 
}} 
@media only screen and (max-width: 992px) {#iihz8e {
width : 249.469px; 
}} 
@media only screen and (max-width: 992px) {#icss0d {
width : 252.469px; 
}} 

  @media only screen and (max-width: 480px) {#ix7n {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ix0scd {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8hrnl {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i0njf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#irt2n {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#iioz4 {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#iuxmg {
height : auto; 
}} 
@media only screen and (max-width: 480px) {#iqzlf {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i8ntp {
font-size : 14px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#inywf {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#iinous {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i22xj {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i7c9b2 {
padding : 10px 10px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ij65 {
padding : 0px 5px 0px 5px; 
}} 

}
  