
#id64dbd5db9255464525647a2f {
  #i25m {
min-height : 100px; 
display : flex; 
} 
#i1au {
padding : 10px; 
min-height : 60px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 12vh; 
} 
#ib43 {
min-height : 100px; 
width : 90%; 
} 
#iq9l {
padding : 10px; 
min-height : 100vh; 
width : 10%; 
display : flex; 
flex-direction : column; 
align-items : center; 
justify-content : flex-start; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#ievj {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : repeat; 
background-position : right center; 
background-attachment : scroll; 
background-size : auto; 
background-image : none; 
} 
#iss9f {
color : black; 
margin : 10px 0 0 0; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ify7b {
width : 100%; 
display : block; 
} 
#i8eor {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iucyx {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i73es {
color : black; 
width : 75px; 
} 
#il6cc {
color : black; 
width : 41px; 
height : 45px; 
} 
#ijvrd {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
position : relative; 
} 
#i9p3l {
padding : 10px; 
min-height : 300px; 
width : 100%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
flex-direction : column; 
} 
#iq338 {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i76os {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ixp2y {
color : black; 
width : 75px; 
} 
#irruz {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iyz7i {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ihmgh {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
height : 10vh; 
} 
#i8iqk {
padding : 10px; 
height : 68.18181610107422px; 
} 
#ikbwp {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#ivg2s {
color : black; 
width : 50px; 
} 
#ivoo4 {
padding : 10px; 
min-height : 80.00003051757812px; 
__background-type : color; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : linear-gradient( 0%,  100%); 
border-radius : 10px 10px 10px 0px; 
border : 0 solid black; 
} 
#ig0z1 {
display : block; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-three-swiper-1 {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-three-swiper-2 {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-three-swiper-3 {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
#i99nx {
padding : 10px; 
__background-type : color; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : local; 
background-size : contain; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
#ilq6k {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i9jci {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ijsmh {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
.swiper-slide.item-threecarrusel1 {
width : 10px; 
padding : 0px 0px 0px 0px; 
min-height : 10auto; 
} 
.swiper-slide.item-threecarrusel2 {
width : 165.2000732421875px; 
padding : 0px 0px 0px 20px; 
min-height : 10auto; 
} 
.swiper-slide.item-threecarrusel3 {
width : 230px; 
padding : 0 0 0 10px; 
} 
.swiper.gjs-three-swiper-1 {
height : auto; 
padding : 0px 10px 0px 10px; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i0ocz {
padding : 5px 5px 0px 5px; 
width : 541.22%; 
height : auto; 
min-height : 10px; 
} 
#i9p7e8 {
padding : 0px 5px 0px 5px; 
width : 37.63%; 
height : auto; 
min-height : 87.7999267578125px; 
} 
#i9m9vs {
padding : 0px 5px 5px 5px; 
width : 38.56%; 
height : auto; 
min-height : 10px; 
} 
.gjs-three-swiper-2.swiper {
height : auto; 
padding : 0px 10px 0px 10px; 
} 
.swiper.gjs-three-swiper-3 {
height : auto; 
padding : 0px 10px 10px 10px; 
} 
.swiper-wrapper {
padding : 0px 0px 0px 0px; 
min-height : 10px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ie1xm {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffe500 0%, #ffe500 100%); 
border : 0 solid black; 
width : 163.9999877929688px; 
float : none; 
left : 480px; 
right : 0px; 
position : relative; 
text-align : center; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
font-weight : 800; 
min-height : 10px; 
padding : 5px 0px 5px 0; 
margin : 0px 0px 0px 0px; 
border-radius : 8px 8px 8px 8px; 
} 
#i76u2 {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#izlgmh {
width : 100%; 
min-height : auto; 
display : block; 
} 
#i1544o {
color : black; 
width : 46.38750610351562px; 
min-height : 10.79998779296875auto; 
} 

  @media only screen and (max-width: 992px) {#ib43 {
width : 85%; 
}} 
@media only screen and (max-width: 992px) {#iq9l {
width : 15%; 
}} 
@media only screen and (max-width: 992px) {#i9m9vs {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#i0ocz {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#i9p7e8 {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#ievj {
min-height : 100vh; 
}} 

  @media only screen and (max-width: 480px) {#i0ocz {
height : 82.75px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ilq6k {
width : 310%; 
}} 
@media only screen and (max-width: 480px) {#i9p7e8 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i9m9vs {
width : 100%; 
}} 

}
  