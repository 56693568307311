
#id64ef61ed9255464525648ddf {
  #i8j5 {
padding : 10px; 
min-height : 100px; 
height : 12vh; 
display : flex; 
justify-content : center; 
border : 0px solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
align-items : center; 
} 
#i3vl {
color : black; 
width : 142px; 
} 
#ij65 {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
height : 88vh; 
justify-content : center; 
display : flex; 
align-items : flex-start; 
border : 1px solid #5e6164; 
} 
#ix7n {
padding : 0px 0px 0px 0; 
min-height : 100px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
margin : 2.5rem 0px 0px 0px; 
box-shadow : 1px 3px 20px -4px black ; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#irt2n {
padding : 10px; 
display : flex; 
float : center; 
justify-content : center; 
font-family : Verdana, Geneva, sans-serif; 
font-size : 1.2rem; 
color : black; 
font-weight : 700; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 45%; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#i5u8d {
height : 0px; 
border : 1px solid black; 
box-shadow : 0px 5px 10px 0px black ; 
} 
.checkboxMain {
width : 300px; 
padding : 0px 0px 0px 0px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
.test {
padding : 0 0px 0px 0px; 
} 
.fullWidth {
width : 100%; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#idxbx {
display : block; 
} 
#imzrq {
padding : 10px; 
min-height : 100px; 
height : 556.46484375px; 
} 
#ivvgk {
min-height : 100px; 
padding : 10px; 
} 
#in0zk {
padding : 10px; 
display : flex; 
flex-direction : column; 
align-items : center; 
} 
#ircvf {
padding : 10px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iq0fs {
padding : 10px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iewch {
padding : 10px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iiuoh {
padding : 10px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
.label-mixclo {
padding : 5px 5px 5px 5px; 
text-align : center; 
} 
.input-mixclo {
padding : 5px 5px 5px 5px; 
border : 1px solid #5e6164; 
} 
#ivlm3 {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
color : white; 
padding : 10px 15px 10px 15px; 
text-align : center; 
width : 200px; 
border-radius : 15px 15px 15px 15px; 
margin : 0 0px 0px 0px; 
} 
.group-input {
flex-direction : column; 
display : flex; 
width : 100%; 
} 
#i1uxk {
margin : 70px 0 0 0; 
} 

  @media only screen and (max-width: 992px) {#ix7n {
width : 70%; 
}} 

  @media only screen and (max-width: 480px) {#ix7n {
width : 100%; 
}} 

}
  