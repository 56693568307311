
#id6542e0c7c239522da2e3ace2 {
  #i25m {
min-height : 100px; 
display : flex; 
} 
#i1au {
padding : 10px; 
min-height : 60px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 12vh; 
} 
#ib43 {
min-height : 100px; 
width : 90%; 
} 
#iq9l {
padding : 10px; 
min-height : 100px; 
width : 10%; 
display : flex; 
flex-direction : column; 
align-items : center; 
justify-content : flex-start; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
height : 100vh; 
} 
#ievj {
padding : 10px; 
min-height : 100px; 
height : 88vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#64d2ff 0%, #64d2ff 100%); 
} 
#iss9f {
color : black; 
margin : 10px 0 0 0; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ify7b {
width : 100%; 
display : block; 
} 
#i8eor {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iucyx {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i73es {
color : black; 
width : 75px; 
} 
#il6cc {
color : black; 
width : 45px; 
height : 45px; 
} 
#ijvrd {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9p3l {
padding : 10px; 
min-height : 300px; 
width : 100%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
flex-direction : column; 
} 
#i9sfh {
color : black; 
width : 107px; 
height : 107px; 
} 
#iq338 {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i9obh {
color : black; 
width : 50px; 
height : 50px; 
} 
#i76os {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ixp2y {
color : black; 
width : 75px; 
} 
#irruz {
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iyz7i {
padding : 10px; 
min-height : 200px; 
width : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ihmgh {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
height : 10vh; 
} 
#i8iqk {
padding : 10px; 
height : 68.18181610107422px; 
} 
#ikbwp {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#ivg2s {
color : black; 
width : 50px; 
} 
#ivoo4 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 10px 10px 10px 0px; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ipcv9 {
padding : 10px; 
display : block; 
font-size : 2rem; 
text-align : center; 
font-weight : 800; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ig0z1 {
display : block; 
} 
#ic1ow {
padding : 10px; 
display : block; 
} 
#ilsii {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ilcyc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ilys7 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#id74t {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ipuwd {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ii0nd {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#id1yzs {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i99fic {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ivf03e {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ilpsk5 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i13cq3 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i14nn9 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i1thlz {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ibdrqx {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#irfues {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i7f7q4 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#idefid {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#imzcmc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ikgk0l {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iwt05k {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#izm9x8 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iyky57 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ic6whr {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#itoy5l {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iox47q {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iwxxok {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ibwsrh {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i118pc {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#irmweu {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i5qaas {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#imr9j8 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#itde1i {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i0udth {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#iqsz8k {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ip6ert {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#imnkof {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i2mdug {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ib8o2p {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ik48el {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#i1szfe {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ifpq6l {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#izslyk {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#ingzw6 {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 
#in38vg {
margin : 0cm 0cm 8pt; 
line-height : 15.6933px; 
font-size : 11pt; 
font-family : Calibri, sans-serif; 
color : rgb(0, 0, 0); 
text-align : justify; 
} 

  @media only screen and (max-width: 992px) {#ib43 {
width : 85%; 
}} 
@media only screen and (max-width: 992px) {#iq9l {
width : 15%; 
}} 

  
}
  